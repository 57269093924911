import React from 'react'
import RedirectHome from '../utils/RedirectHome'
import { Typography } from '@mui/material'
import Cookies from 'js-cookie'



export default function LogoutPage(props){

    Cookies.remove('user')
    Cookies.remove('rank')
    Cookies.remove('hash')

    return(
        <>
            <Typography variant="h3">Exit from dashboard..</Typography>

            <RedirectHome />
        </>
    )
}