import React, { useEffect, useState } from "react";
import { FormControl, Button, TextField, Grid, Typography } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const LoginForm = ({ onSubmit }) => {
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(true);

    useEffect(function(){
      if(window.location.href.includes("demo.tvcas.com")){
        setUser("admin")
        setPassword("admin")
      }
    }, [])
  
    const handleSubmit = (event) => {
      event.preventDefault();
      onSubmit({ user, password, remember });
      setUser(''); setPassword('');
    };

    const handleKeyPress = (e) => {
      if(e.key==="Enter"){
        onSubmit({ user, password, remember });
        setUser(''); setPassword('');
      }
    }


  
    return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item>
        <Typography variant="h4">Login</Typography>
        <FormControl>
          <TextField
            label="Login"
            value={user}
            onChange={(event) => setUser(event.target.value)}
            required
            style={{ marginTop:"20px"}}
          />
          <TextField
            label="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyPress={(handleKeyPress)}
            required
            variant="outlined"
            type="password"
            style={{ marginTop:"20px"}}
          />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              //checked={remember}
              label="Remember me"
              onChange={(event) => setRemember(event.target.checked)}
            />
          </FormGroup>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginTop:"30px"}}
          >
            Login
          </Button>
        </FormControl>
        </Grid>
    </Grid>
    );
  };
  
  export default LoginForm;