import { DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { sendDataToServer } from "utils/functions";

export default function DialogInfoDir({folder}){

    const [infoDir, setInfoDir] = useState({})

    useEffect(function(){
        const fetchData = () => {
            sendDataToServer({op: "getInfoFolder", folder: folder}).then(r => {
                setInfoDir(r)
            })
        }
        fetchData()
        const interval = setInterval(fetchData, 1000)
        return () => clearInterval(interval)
    // eslint-disable-next-line
    }, [])

    return (
        <>
            <DialogTitle id="alert-dialog-title">{folder} ({infoDir.size} Mb, {infoDir.count} files)</DialogTitle>
            <DialogContent> 
                    <DialogContentText id="alert-dialog-description" style={{fontSize:"14px", color: "#111"}}>
                    <b>Start</b>: {infoDir["starttime"]}<br />{infoDir["startfile"]} ({infoDir["startsize"]} Mb)<br />
                    ...<br />
                    <b>Finish</b>: {infoDir["finishtime"]}<br />{infoDir["finishfile"]} ({infoDir["finishsize"]} Mb)
                    </DialogContentText>
            </DialogContent>
        </>
    )
}