import React, { useRef } from 'react';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import { Tooltip } from '@mui/material';

const LogoUpload = ({chid}) => {
  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    fetch(process.env.REACT_APP_LOGO + "?chid="+chid, {
      method: 'POST',
      body: formData,
    })
    .then(async response => {
        if (response.ok) {
          const text = await response.text(); // Получаем текст из ответа
          if(text === "OK"){
            window.location.href = ""
          }
        } else {
          throw new Error('Error upload file!');
        }
    })
    .catch(error => {
      console.error("Something error to upload file!", error)
    });
  };

  return (
    <>
      <input
        type="file"
        accept=".png"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <Tooltip title="Add logotype">
        <AddAPhotoOutlinedIcon onClick={handleFileUpload} className="btnIcon" />
      </Tooltip>
    </>
  )
}

export default LogoUpload;
