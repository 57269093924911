import React, { useState } from 'react'
import LoginForm from '../components/LoginForm'
import Cookies from 'js-cookie';
import { md5hash, sendDataToServer } from '../utils/functions';
import { Alert, Snackbar } from '@mui/material';



export default function LoginPage(props){
    var rem = {}

    const [open, setOpen] = useState(false)

    
    function handleClose(event, reason){
        // if (reason === 'clickaway') {
        // return;
        // }
        setOpen(false);
    };


    return(
        <>
            <LoginForm onSubmit={(data) => {
                data["password"] = md5hash(data["password"])
                const newdata = {...data, 'op': "login"}
                
                if (data.remember){
                    rem = {expires: 60}
                }
                sendDataToServer(newdata).then(r => {
                    if(r.error){
                        setOpen(true);
                    }else{
                        Cookies.set('user', r.user, rem)
                        Cookies.set('rank', r.rank, rem)
                        Cookies.set('hash', r.hash, rem)
                        window.location.href=""
                    }
                })
            }} />

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{vertical:"bottom", horizontal:"center"}}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Login / password incorrect!
                </Alert>
            </Snackbar>
        </>
    )
}