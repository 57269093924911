import React, { useEffect, useState } from 'react'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { convUnixDate, sendDataToServer } from '../utils/functions';
import { Alert, AlertTitle, Box, Button, FormHelperText, TextField } from '@mui/material';
import Cookies from 'js-cookie'



export default function ConfigPage(props) {
    const [conff, setConff] = useState([]);

    

    useEffect(() => {
        sendDataToServer({ 'op': "getConf" })
            .then(r => {
                setConff(r);
            });
    }, []);

    function handleSubmit(e){
        e.preventDefault();
        sendDataToServer({
            'op' : "setConf",
            'formData' : conff
        }).then(() => {
            //if(r.status === "OK"){
                Cookies.remove('user')
                Cookies.remove('rank')
                Cookies.remove('hash')
                window.location.href="/"
            //}else{
            //   alert(r.status)
            //} 
        })
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
    
        setConff(prevConff => {
            return prevConff.map(confItem => {
                if (confItem.key === name) {
                    return { ...confItem, value: value };
                } else {
                    return confItem;
                }
            });
        });
    };













    // ██████╗ ███████╗████████╗██╗   ██╗██████╗ ███╗   ██╗
    // ██╔══██╗██╔════╝╚══██╔══╝██║   ██║██╔══██╗████╗  ██║
    // ██████╔╝█████╗     ██║   ██║   ██║██████╔╝██╔██╗ ██║
    // ██╔══██╗██╔══╝     ██║   ██║   ██║██╔══██╗██║╚██╗██║
    // ██║  ██║███████╗   ██║   ╚██████╔╝██║  ██║██║ ╚████║
    // ╚═╝  ╚═╝╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═══╝
                                                        


    return (
        <>
            <h2>
                <SettingsSuggestIcon style={{ marginRight: "10px" }} />
                Configuration server
            </h2>

            <hr />

            <Alert severity="error" style={{marginBottom: "30px"}}>
                <AlertTitle>Sensitive parameters!</AlertTitle>
                Be careful when changing settings. Most of them will enter into force after the server restart.
            </Alert>
            <form onSubmit={handleSubmit} >
            <table width='100%'>
                <tbody>
                    {
                        conff.map((v, index) => (
                             <tr key={"kk+" + index}>
                                <td
                                    align="right"
                                    valign="top"
                                    style={{paddingTop: "30px"}}
                                    width="30%">
                                    {v.descr}
                                </td>
                             <td style={{padding:"10px 0 5px 10px"}}>
                             <Box
                                sx={{
                                    width: 500,
                                    maxWidth: '100%',
                                }}
                                >
                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        name={v.key}
                                        label={v.key}
                                        variant="outlined"
                                        value={conff[index].value} 
                                        disabled={false}
                                        onBlur={handleChange}
                                        onChange={handleChange}
                                        error={false} 
                                    />
                                    <FormHelperText>{
                                        (v.value !== v.last && v.last !== "") && "Last value: " + v.last + ". Change " + convUnixDate(Number(v.e_time)>0 ? v.e_time : 0)
                                    }</FormHelperText>
                                </Box>
                             </td>
                             </tr>
                        ))
                    }
                    <tr><td></td><td style={{padding:"30px 10px"}}>
                        <Button variant="contained" type="submit" color={"primary"}>
                            Save & restart
                        </Button>
                    </td></tr>
                </tbody>
            </table>
            </form>
        </>
    );
}