import { DialogContent, DialogContentText, DialogTitle, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { convUnixDate, sendDataToServer, time } from "utils/functions";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export default function EpgShow({id}){

    const [data, setData] = useState({})


    useEffect(function(){
        sendDataToServer({op: "getEpg", id}).then(r => {
            setData(r)
        })
    }, [id])


    return (
        <>
            <DialogTitle id="alert-dialog-title" style={{display:"flex",  justifyContent:"space-between", borderBottom:"2px dashed #aaa"}}>
                <span>
                    <img src={process.env.REACT_APP_URL + "/service/logos?id="+id} height="50px" alt="" style={{marginRight:"20px"}} />
                    <span style={{position:"relative", bottom:"15px"}}>{data["chname"]}</span>
                </span>
                <span style={{fontWeight:"bold", fontSize:"14px", marginTop:"20px", color: data["u_time"] > time()-700 ? "green" : "red"}}>{convUnixDate(data["u_time"])}</span>
                <span>
                    <Tooltip title="Clear EPG">
                        <DeleteOutlineIcon style={{paddingLeft:"30px", position:"relative", top:"15px"}} className="btnIcon" 
                            onClick={() => {
                                sendDataToServer({op: "clearEpg", id}).then(r => {
                                    if (r["status"] === "OK"){
                                        window.location.href=""
                                    }
                                })
                            }}
                        />
                    </Tooltip>
                </span>
            </DialogTitle>
            <DialogContent> 
                    <DialogContentText style={{marginTop:"20px", font:"12px/1.1em Tahoma", color: "#aaa"}} id="alert-dialog-description">
                    {
                        Object.keys(data).map(k => (
                            (data[k]["s_time"] && <li key={k} style={{ listStyleType:"none", color: (time()>data[k]["s_time"] && "#000")}}><b>{convUnixDate(data[k]["s_time"])}</b> {data[k]["title"]}</li>)
                        ))
                    }
                    </DialogContentText>
            </DialogContent>
        </>
    )
}

