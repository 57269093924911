import axios from 'axios'
//import dotenv from 'dotenv'
import { MD5 } from 'crypto-js';


export const sendDataToServer = async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_PATH, data);
      if (response.statusText === "OK"){
        if ("data" in response.data){
            const tmp1 = response.data
            const tmp2 = []
            for( const k in tmp1.data) { // ещё один раз в дату
              if (Number(k) > 0){
                tmp2.push(tmp1.data[k])  
              }else{
                if ("error" in tmp1){
                  console.error(tmp1.error)
                }
                if ("error" in tmp1.data){
                  console.error(tmp1.data.error)
                }
                return tmp1.data
              }
            }
            return tmp2
        }
        return response.data
      }
    } catch (error) {
      //alert(error.message)
      console.error('Error request data:', error);
    }
  }; 




export  function md5hash(str) {
  return MD5(str).toString();
}

export function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomString(length) {
  const characters = 'abcdefghijklmnopqrstuvwxyz';
  let result = '';
  for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
  }
  return result;
}

export function transliterate(text) {
  const cyrillicToLatinMap = {
      'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
      'е': 'e', 'ё': 'yo', 'ж': 'zh', 'з': 'z', 'и': 'i',
      'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
      'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
      'у': 'u', 'ф': 'f', 'х': 'kh', 'ц': 'ts', 'ч': 'ch',
      'ш': 'sh', 'щ': 'shch', 'ъ': '', 'ы': 'y', 'ь': '',
      'э': 'e', 'ю': 'yu', 'я': 'ya',
      'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D',
      'Е': 'E', 'Ё': 'Yo', 'Ж': 'Zh', 'З': 'Z', 'И': 'I',
      'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N',
      'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T',
      'У': 'U', 'Ф': 'F', 'Х': 'Kh', 'Ц': 'Ts', 'Ч': 'Ch',
      'Ш': 'Sh', 'Щ': 'Shch', 'Ъ': '', 'Ы': 'Y', 'Ь': '',
      'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya', ' ':'_' }
      return text.split('').map(function(char) {
        return cyrillicToLatinMap[char] || char;
    }).join('');
  };

export  function arrayChunk(arr, chunkSize) {
    const chunkedArray = [];
    
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      chunkedArray.push(chunk);
    }
    
    return chunkedArray;
}



export function ins(str, search){
  if (!search || search.trim() === "") {
      return str; // Вернуть исходную строку, если поисковая строка пустая
  } else {
      const exp = new RegExp(search, "gi");
      const replacedStr = str.replace(exp, "<span class='ins'>"+search+"</span>");
      return <span dangerouslySetInnerHTML={{ __html: replacedStr }} />;    
  }
}



export  function convUnixDate(unixTime){
    unixTime = parseInt(unixTime)
    if (unixTime <= 10) return ""
    const date = new Date(unixTime * 1000)
    const dateNow = new Date()
    const timeLast = (dateNow.getTime()/1000)-86400
    const dateLast = new Date(timeLast * 1000)
    const timeTomor = (dateNow.getTime()/1000)+86400
    const dateTomor = new Date(timeTomor * 1000)
    const Y = date.getFullYear();
    let m = date.getMonth() + 1; // Месяцы в JavaScript начинаются с 0
    let d = date.getDate();
    let H = date.getHours();
    let i = date.getMinutes();
    let s = date.getSeconds();
    var ddate = ""
    if(dateNow.getFullYear() === Y &&
            (dateNow.getMonth()+1) === m &&
                    dateNow.getDate() === d){
            ddate = "Today"
        }else if(dateLast.getFullYear() === Y &&
                    (dateLast.getMonth()+1) === m &&
                        dateLast.getDate() === d){
             ddate = "Yesterday"
        }else if (dateTomor.getFullYear() === Y &&
                    (dateTomor.getMonth()+1) === m &&
                        dateTomor.getDate() === d){
             ddate = "Tomorrow"
        }else{
          if(m<10) m = "0"+m
          if(d<10) d = "0"+d
          ddate = d+"."+m+"."+Y
        }


        if(H<10) H = "0"+H
        if(i<10) i = "0"+i
        if(s<10) s = "0"+s

    return ddate + " " + H + ":" + i// + ":" + s
}


export function time() {
  return Math.floor(new Date().getTime() / 1000);
};

