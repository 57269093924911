import React, { useEffect, useState } from "react";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import { convUnixDate, sendDataToServer } from "utils/functions";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';


export default function StreamerPage(){

    const [streams, setStreams] = useState([])
    const [showScreen, setShowScreen] = useState(-1)
    const [edit, setEdit] = useState({})
    const [editshow, setEditshow] = useState(false)
    const [info, setInfo] = useState("")


    useEffect(() => {
        const updateEverySecond = () => {
            sendDataToServer({ 'op': "getStreams" })
            .then(r => {
                setStreams(r);
                //console.log(r)
            });
        }
        updateEverySecond()
        const intervalId = setInterval(updateEverySecond, 500);
        return () => clearInterval(intervalId);
    }, [])




    return (
        <>
        
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <h2>
                    <PlayCircleOutlineIcon style={{ marginRight: "10px", position:"relative", top:"2px" }} />
                    Streamer
                </h2>
                <span style={{marginTop:"20px"}}>
                    <Tooltip title="Background">
                        <span className="tipa-a"
                            onClick={()=>setShowScreen(0)}
                        ><InsertPhotoIcon /> <span style={{position:"relative", bottom:"5px"}}>Main</span></span>
                     </Tooltip>
                </span>
            </div><hr />

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>URI</TableCell>
                        <TableCell>Play</TableCell>
                        <TableCell>IP</TableCell>
                        <TableCell>Busy</TableCell>
                        <TableCell>Restart</TableCell>
                        <TableCell>Edited</TableCell>
                        <TableCell align="right">Options</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {streams.map((row) => (
                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell className={"uristream"} style={{cursor:"pointer", color: row["enable"]==="1"?"green":"red"}}>
                                <span onClick={() => {
                                    setEdit(row)
                                    setEditshow(true)
                                }}>{row.uri}</span>
                            </TableCell>
                            <TableCell>
                                <span style={{borderBottom: "1px dashed #000", cursor:"pointer"}} onClick={() => {
                                    sendDataToServer({op:"getInfo", play:row.play}).then(r=>{
                                        setInfo(r)
                                    })
                                }} >{row.play}
                                </span>
                            </TableCell>
                            <TableCell>{row.ip}</TableCell>
                            <TableCell>{convUnixDate(row.b_time)}</TableCell>
                            <TableCell>{convUnixDate(row.s_time)}</TableCell>
                            <TableCell>{convUnixDate(row.e_time)}</TableCell>
                            <TableCell align="right">
                                <Tooltip title="On / off">
                                    <PowerSettingsNewIcon className="btnIcon" onClick={
                                        () => sendDataToServer({ op: "switchOnOff", id: row.id, state: row.enable })
                                    } />
                                </Tooltip>
                                <Tooltip title="Background">
                                    <InsertPhotoIcon className="btnIcon"
                                        onClick={() =>
                                            setShowScreen(row.id)
                                    }/>
                                </Tooltip>
                                <a href={row["link"]} target="_blank" rel="noreferrer"><ShareOutlinedIcon className="btnIcon" /></a>
                            </TableCell>
                        </TableRow>
                        ))
                    }
                    </TableBody>
                </Table>
            </TableContainer>





{/* 
██████╗ ██╗ █████╗ ██╗      ██████╗  ██████╗     ███████╗ ██████╗██████╗ ███████╗███████╗███╗   ██╗███████╗██╗  ██╗ ██████╗ ████████╗
██╔══██╗██║██╔══██╗██║     ██╔═══██╗██╔════╝     ██╔════╝██╔════╝██╔══██╗██╔════╝██╔════╝████╗  ██║██╔════╝██║  ██║██╔═══██╗╚══██╔══╝
██║  ██║██║███████║██║     ██║   ██║██║  ███╗    ███████╗██║     ██████╔╝█████╗  █████╗  ██╔██╗ ██║███████╗███████║██║   ██║   ██║   
██║  ██║██║██╔══██║██║     ██║   ██║██║   ██║    ╚════██║██║     ██╔══██╗██╔══╝  ██╔══╝  ██║╚██╗██║╚════██║██╔══██║██║   ██║   ██║   
██████╔╝██║██║  ██║███████╗╚██████╔╝╚██████╔╝    ███████║╚██████╗██║  ██║███████╗███████╗██║ ╚████║███████║██║  ██║╚██████╔╝   ██║   
╚═════╝ ╚═╝╚═╝  ╚═╝╚══════╝ ╚═════╝  ╚═════╝     ╚══════╝ ╚═════╝╚═╝  ╚═╝╚══════╝╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝ ╚═════╝    ╚═╝   
                                                                                                                                      */}
            <Dialog open={parseInt(showScreen) >= 0? true : false} onClose={() => setShowScreen(-1)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                >
                <img src={process.env.REACT_APP_URL + "/service/?op=getBackground&id="+showScreen}
                    alt=""
                />

            </Dialog>      







{/* 
██████╗ ██╗ █████╗ ██╗      ██████╗  ██████╗     ███████╗██████╗ ██╗████████╗                        
██╔══██╗██║██╔══██╗██║     ██╔═══██╗██╔════╝     ██╔════╝██╔══██╗██║╚══██╔══╝                        
██║  ██║██║███████║██║     ██║   ██║██║  ███╗    █████╗  ██║  ██║██║   ██║                           
██║  ██║██║██╔══██║██║     ██║   ██║██║   ██║    ██╔══╝  ██║  ██║██║   ██║                           
██████╔╝██║██║  ██║███████╗╚██████╔╝╚██████╔╝    ███████╗██████╔╝██║   ██║                           
╚═════╝ ╚═╝╚═╝  ╚═╝╚══════╝ ╚═════╝  ╚═════╝     ╚══════╝╚═════╝ ╚═╝   ╚═╝                           
*/}

            <Dialog open={editshow} onClose={() => setEditshow(false)}
                        aria-labelledby="alert-dialog-title2"
                        aria-describedby="alert-dialog-description2"
                >
                
                <DialogTitle id="alert-dialog-title2">{"Change URI for archive #" + edit.id}</DialogTitle>
                <DialogContent>
                    <TextField
                                fullWidth   
                                value={edit.uri }
                                label={"Stream URI"}
                                name={"uri"}
                                onChange={e => {
                                    setEdit({...edit, uri:e.target.value})
                                }}
                                style={{margin:"15px 0"}}
                        />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        sendDataToServer({op: "editStream", id: edit.id, uri: edit.uri}).then(r => {
                            if (r["status"]==="OK"){
                                setEditshow(false)
                            }
                        })
                    }}>Edit</Button>
                    <Button onClick={() => setEditshow(false)}>Cancel</Button>
                </DialogActions>

            </Dialog>   




{/* 
██████╗ ██╗ █████╗ ██╗      ██████╗  ██████╗     ██╗███╗   ██╗███████╗ ██████╗ 
██╔══██╗██║██╔══██╗██║     ██╔═══██╗██╔════╝     ██║████╗  ██║██╔════╝██╔═══██╗
██║  ██║██║███████║██║     ██║   ██║██║  ███╗    ██║██╔██╗ ██║█████╗  ██║   ██║
██║  ██║██║██╔══██║██║     ██║   ██║██║   ██║    ██║██║╚██╗██║██╔══╝  ██║   ██║
██████╔╝██║██║  ██║███████╗╚██████╔╝╚██████╔╝    ██║██║ ╚████║██║     ╚██████╔╝
╚═════╝ ╚═╝╚═╝  ╚═╝╚══════╝ ╚═════╝  ╚═════╝     ╚═╝╚═╝  ╚═══╝╚═╝      ╚═════╝  */}
                                                                               
                <Dialog open={info.title ? true : false} onClose={() => setInfo({})}
                        aria-labelledby="alert-dialog-title3"
                        aria-describedby="alert-dialog-description3"
                >
                
                <DialogTitle id={"alert-dialog-title3"}>
                    <img height={50} src={process.env.REACT_APP_URL + "/service/logos?id="+info.chid} alt={info.chname} />
                    <span style={{position:"relative", bottom: "17px", left: "15px"}}>{info.chname}</span>
                </DialogTitle>
                <DialogContent>
                    <p>{convUnixDate(info.s_time)} - {convUnixDate(info.f_time)}</p>
                    <p><b style={{fontStyle:"italic"}}>{info.title}</b></p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setInfo({})}>OK</Button>
                </DialogActions>

            </Dialog>                                                                           
                                                                               
                                                                               
                                                                               
                                                                               
                                                                               
                                                                                       </>
    )
}