import React, { useEffect, useState } from 'react'
import DvrIcon from '@mui/icons-material/Dvr';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Alert, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { convUnixDate, sendDataToServer, transliterate } from 'utils/functions';
import Paper from '@mui/material/Paper';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import LoadingDots from 'components/LoadingDots';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import LogoUpload from 'components/LogoUpload';
import EpgShow from 'components/EpgShow';
import DialogInfoDir from 'components/DialogInfoDir';




export default function ChannelsPage(props){


    const [channels, setChannels] = useState([])
    const [modal, setModal] = useState("")
    const [upd, setUpd] = useState(0)
    const [edit, setEdit] = useState({})
    const [del, setDel] = useState(0)
    const [scan, setScan] = useState(false)
    const [scanResult, setScanResult] = useState([])
    const [showScreen, setShowScreen] = useState(0)
    const [showEpg, setShowEpg] = useState(0)
    const [infoDir, setInfoDir] = useState("")
    const [confimDeleteLogotype, setConfimDeleteLogotype] = useState(0)


    const styleScan = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    useEffect(() => {
        sendDataToServer({ 'op': "getChannels" })
            .then(r => {
                setChannels(r);
                //console.log(r)
            });
    }, [upd]);



    useEffect(() => {
        var request = true
        const interval = setInterval(() => {
            if(scan && request){
                request = false
                sendDataToServer({op: "scanUdp"}).then(r =>{
                    var is = false
                    for (let i = 0; i < scanResult.length; i++) {
                        if(r["scan"][0] === scanResult[i][0]){
                            is = true
                            break
                        }
                    }

                    if(is === false && r["scan"].length===2){
                        r["scan"].push("/mnt/sda/" + transliterate(r["scan"][1]).toLowerCase())
                        setScanResult(scanResult => [...scanResult, r["scan"]])
                        //console.log(r["scan"])
                    }
                    request = true
                })
            }
        }, 500)
        return () => clearInterval(interval);
    }, [scan, scanResult])



    function handleChangeCheckbox(e){
        if(e.target.name === "enable"){
            setEdit({...edit, enable:e.target.checked})
        }else if(e.target.name === "visible"){
            setEdit({...edit, visible:e.target.checked})
        }
    }


    function changeScanResult(e, k){
        var v = e.target.value
        if (e.target.name === "chname"){
            scanResult[k][1] = v
        }else if (e.target.name === "folder"){
            scanResult[k][2] = v
        }
        setScanResult(scanResult)
    }


    function handleDelete(id){
        sendDataToServer({op: "deleteChannel", id}).then(r => {
            if (r["status"] === "OK"){
                setDel(0)
                setUpd(Math.random())
            }
        })
    }


    function handleSendForm(e){
        e.preventDefault();
        edit.enable = (edit.enable === true || edit.enable === "1") ? "1" : "0"
        edit.visible = (edit.visible === true || edit.visible === "1") ? "1" : "0"
        if(edit.chname && edit.uri && edit.folder){
            sendDataToServer({
                    ...edit,
                    op: modal.toLowerCase()+"Channel"
            }).then(r => {
                if(r["status"]==="OK"){
                    setEdit({})
                    setModal("")
                    setUpd(Math.random())
                }else{
                    alert("Error connection with server..")
                }
            })
        }  else{
            alert("Fill in all fields correctly!")
        }
    }   






    return(
        <>
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <h2>
                    <DvrIcon style={{ marginRight: "10px", position:"relative", top:"2px" }} />
                    Channels
                </h2>
                <span style={{marginTop:"20px"}}>
                        <Button variant="contained" onClick={() => setModal("Add")}><AddCircleOutlineIcon /> <span style={{paddingTop:"3px"}}>Add</span></Button>
                        <Button style={{marginLeft:"10px"}} variant="outlined" onClick={() => setScan(true) }><FindInPageIcon /> <span style={{paddingTop:"3px"}}>UDP</span></Button>
                </span>
            </div><hr />

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Channel name</TableCell>
                        <TableCell>URI</TableCell>
                        <TableCell>Record folder</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Edited</TableCell>
                        <TableCell>EPG Update</TableCell>
                        <TableCell align="right">Options</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {channels.map((row) => (
                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell component="th" scope="row" style={{color: (row.visible==="0" ? "red" : "green")}}>
                                <img src={process.env.REACT_APP_URL + "/service/logos?id="+row["id"]}
                                    height="20px" alt="" style={{marginRight:"10px", position:"relative", top:"3px"}}
                                    onClick={() => setConfimDeleteLogotype(row.id)}
                                />
                                <span className="chname"
                                    onClick={() => setShowEpg(row["id"])}
                                    >{row.chname}
                                </span>
                            </TableCell>
                            <TableCell>{row.uri}</TableCell>
                            <TableCell><PrivacyTipOutlinedIcon style={{fontSize:"16px", marginRight:"10px", position:"relative", top:"3px"}} className='tipa-a'
                                onClick={() => setInfoDir(row.folder)}
                            />
                                <span style={{color: (row.enable==="0" ? "red" : "green")}}>{row.folder}</span>
                            </TableCell>
                            <TableCell>{convUnixDate(row.c_time)}</TableCell>
                            <TableCell>{convUnixDate(row.e_time)}</TableCell>
                            <TableCell>{convUnixDate(row.u_time)}</TableCell>
                            <TableCell align="right">
                                <Tooltip title="Edit channel">
                                    <BorderColorIcon className="btnIcon" onClick={
                                        () => {
                                            setEdit(row)
                                            setModal("Edit")
                                        }
                                    } />
                                </Tooltip>
                                <Tooltip title="Screenshot"><InsertPhotoIcon className="btnIcon"
                                    onClick={() =>
                                        setShowScreen(row.id)
                                    }
                                /></Tooltip>
                                
                                <LogoUpload chid={row.id} />
                                                                              
                                <Tooltip title="Delete channel">
                                    <DeleteOutlineIcon className="btnIcon" style={{marginLeft:"10px"}} onClick={() => setDel(row.id)} />
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

{/* ███████╗██████╗ ██╗████████╗    ███████╗ ██████╗ ██████╗ ███╗   ███╗
    ██╔════╝██╔══██╗██║╚══██╔══╝    ██╔════╝██╔═══██╗██╔══██╗████╗ ████║
    █████╗  ██║  ██║██║   ██║       █████╗  ██║   ██║██████╔╝██╔████╔██║
    ██╔══╝  ██║  ██║██║   ██║       ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
    ███████╗██████╔╝██║   ██║       ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
    ╚══════╝╚═════╝ ╚═╝   ╚═╝       ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
                                                                     */}


            <Modal
                open={modal?true:false}
                onClose={() => {
                    setModal("")
                    setEdit({})
                }}
                aria-labelledby="edit-modal-title"
                aria-describedby="edit-modal-description"
            >
                <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            border: '1px solid #000',
                            boxShadow: 10,
                            p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2"><BorderColorIcon /> {modal} channel</Typography>

                    <hr />
                    <form onSubmit={handleSendForm} >
                        <TextField
                                fullWidth   
                                value={edit.chname}
                                label={"Channel name"}
                                name={"chname"}
                                onChange={e => {
                                    setEdit({...edit, chname:e.target.value})
                                }}
                                style={{margin:"15px 0"}}
                        />
                        <TextField
                                fullWidth   
                                value={edit.uri}
                                label={"URI"}
                                name={"uri"}
                                onChange={e => {
                                    setEdit({...edit, uri:e.target.value})
                                }}
                                style={{margin:"15px 0"}}
                        />
                        <TextField
                                fullWidth   
                                value={edit.xmlid}
                                label={"ID from XMLTV-file"}
                                name={"xmlid"}
                                onChange={e => {
                                    setEdit({...edit, xmlid:e.target.value})
                                }}
                                style={{margin:"15px 0"}}
                        />
                        <TextField
                                disabled={modal==="Edit"&&true}
                                fullWidth   
                                value={edit.folder}
                                label={"Record folder"}
                                name={"folder"}
                                onChange={e => {
                                    setEdit({...edit, folder:e.target.value})
                                }}
                                style={{margin:"15px 0"}}
                        />

                        <Grid container>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    onChange={handleChangeCheckbox}
                                    name={"enable"}
                                    style={{marginTop: "5px"}}
                                    control={<Checkbox />}
                                    label="Recording is on"
                                    checked={(edit.enable === true || edit.enable === "1") && true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    onChange={handleChangeCheckbox}
                                    name={"visible"}
                                    style={{marginTop: "5px"}}
                                    control={<Checkbox />}
                                    label="Visible to the client"
                                    checked={(edit.visible === true || edit.visible === "1") && true}
                                />
                            </Grid>
                        </Grid>



                        <div style={{textAlign:"right", marginTop:"20px"}}>
                            <Button variant="contained" type="submit"><BorderColorIcon style={{fontSize:"14px", marginRight:"10px"}} /> {modal}</Button>
                            <Button variant="outlined" style={{marginLeft:"5px"}} type="submit" onClick={() => {
                                setModal("")
                                setEdit({})
                                return!1
                            }}>Cancel</Button>
                        </div>
                    </form>
                </Box>
            </Modal>



{/*
███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗         ███████╗ ██████╗ █████╗ ███╗   ██╗
████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║         ██╔════╝██╔════╝██╔══██╗████╗  ██║
██╔████╔██║██║   ██║██║  ██║███████║██║         ███████╗██║     ███████║██╔██╗ ██║
██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║         ╚════██║██║     ██╔══██║██║╚██╗██║
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗    ███████║╚██████╗██║  ██║██║ ╚████║
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝    ╚══════╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═══╝
                                                                                   */}

            <Modal
                open={scan?true:false}
                onClose={setScan}
                aria-labelledby="modal-modal-title1"
                aria-describedby="modal-modal-description1"
            >
            <Box sx={styleScan}>
                <Typography id="modal-modal-title1" variant="h6" component="h2" style={{display:"flex", justifyContent:"space-between"}}><span><FindInPageIcon />
                        <span style={{position:"relative", bottom:"5px"}}>UDP channels scanning<LoadingDots /></span>
                    </span>
                    <span className="tipa-a"><Tooltip title="Clear scan results"><ReplayCircleFilledIcon
                        onClick={() => {
                            setScanResult([])
                        }
                    } /></Tooltip></span>
                </Typography>
                
                    <table className="tableScan"><tbody>
                    {
                        scanResult.map((v, k) => (
                            <tr key={k}>
                                <td style={{fontSize: "11px"}}>{k+1}.</td>
                                <td><input type="text" value={"udp://@"+v[0]} disabled="disable" /></td>
                                <td><input type="text" name="chname" defaultValue={scanResult[k][1]}
                                    onChange={e => changeScanResult(e, k)}        
                                /></td>
                                <td><input type="text" name="folder" defaultValue={scanResult[k][2]}
                                    onChange={e => changeScanResult(e, k)}
                                /></td>
                                <td><AddCircleOutlineIcon style={{fontSize:"16px"}} className="tipa-a"
                                    onClick={(e) => {
                                        sendDataToServer({
                                                chname: v[1],
                                                uri: "udp://@"+v[0],
                                                folder: scanResult[k][2],
                                                enable: "0",
                                                visible: "0",
                                                op: "addChannel"
                                        }).then(r => {
                                            if(r["status"]==="OK"){
                                                setUpd(Math.random())
                                                e.target.style.display = 'none'
                                            }else{
                                                alert(r["status"])
                                            }
                                        })
                                    }}
                                /></td>
                            </tr>
                        ))
                    }
                    </tbody></table>

                <hr />
                <center><Button onClick={() => setScan(false)}>Close</Button></center>
            </Box>
            </Modal>

{/* ██████╗ ██╗ █████╗ ██╗      ██████╗  ██████╗     ██████╗ ███████╗██╗     ███████╗████████╗███████╗
    ██╔══██╗██║██╔══██╗██║     ██╔═══██╗██╔════╝     ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
    ██║  ██║██║███████║██║     ██║   ██║██║  ███╗    ██║  ██║█████╗  ██║     █████╗     ██║   █████╗  
    ██║  ██║██║██╔══██║██║     ██║   ██║██║   ██║    ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝  
    ██████╔╝██║██║  ██║███████╗╚██████╔╝╚██████╔╝    ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
    ╚═════╝ ╚═╝╚═╝  ╚═╝╚══════╝ ╚═════╝  ╚═════╝     ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝
                                                                                                   */}

            <Dialog open={parseInt(del) > 0 || del===-1 ? true : false} onClose={setDel}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
                <DialogContent>
                    <Alert color="warning">
                        {`All recordings of the TV channel will be deleted`}
                    </Alert>    
                    <DialogContentText style={{marginTop:"20px", textAlign:"center"}} id="alert-dialog-description">
                        Are you sure delete this channel?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => handleDelete(del)}>Yes</Button>
                    <Button onClick={() => setDel(0)}>No</Button>
                </DialogActions>
            </Dialog>



{/* 
██████╗ ██╗ █████╗ ██╗      ██████╗  ██████╗     ███████╗ ██████╗██████╗ ███████╗███████╗███╗   ██╗███████╗██╗  ██╗ ██████╗ ████████╗
██╔══██╗██║██╔══██╗██║     ██╔═══██╗██╔════╝     ██╔════╝██╔════╝██╔══██╗██╔════╝██╔════╝████╗  ██║██╔════╝██║  ██║██╔═══██╗╚══██╔══╝
██║  ██║██║███████║██║     ██║   ██║██║  ███╗    ███████╗██║     ██████╔╝█████╗  █████╗  ██╔██╗ ██║███████╗███████║██║   ██║   ██║   
██║  ██║██║██╔══██║██║     ██║   ██║██║   ██║    ╚════██║██║     ██╔══██╗██╔══╝  ██╔══╝  ██║╚██╗██║╚════██║██╔══██║██║   ██║   ██║   
██████╔╝██║██║  ██║███████╗╚██████╔╝╚██████╔╝    ███████║╚██████╗██║  ██║███████╗███████╗██║ ╚████║███████║██║  ██║╚██████╔╝   ██║   
╚═════╝ ╚═╝╚═╝  ╚═╝╚══════╝ ╚═════╝  ╚═════╝     ╚══════╝ ╚═════╝╚═╝  ╚═╝╚══════╝╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝ ╚═════╝    ╚═╝   
                                                                                                                                      */}
            <Dialog open={parseInt(showScreen) > 0? true : false} onClose={() => setShowScreen(0)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                >
                <img src={process.env.REACT_APP_URL + "/service/?op=getScreenshot&id="+showScreen}
                    alt=""
                />

            </Dialog>


{/* 
██████╗ ██╗ █████╗ ██╗      ██████╗  ██████╗     ███████╗██████╗  ██████╗ 
██╔══██╗██║██╔══██╗██║     ██╔═══██╗██╔════╝     ██╔════╝██╔══██╗██╔════╝ 
██║  ██║██║███████║██║     ██║   ██║██║  ███╗    █████╗  ██████╔╝██║  ███╗
██║  ██║██║██╔══██║██║     ██║   ██║██║   ██║    ██╔══╝  ██╔═══╝ ██║   ██║
██████╔╝██║██║  ██║███████╗╚██████╔╝╚██████╔╝    ███████╗██║     ╚██████╔╝
╚═════╝ ╚═╝╚═╝  ╚═╝╚══════╝ ╚═════╝  ╚═════╝     ╚══════╝╚═╝      ╚═════╝ 
                                                                           */}
            <Dialog open={parseInt(showEpg) > 0? true : false} onClose={() => setShowEpg(0)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
            >
                <EpgShow id={showEpg} />

            </Dialog>


{/*
██████╗ ██╗ █████╗ ██╗      ██████╗  ██████╗     ██╗███╗   ██╗███████╗ ██████╗     ██████╗ ██╗██████╗ 
██╔══██╗██║██╔══██╗██║     ██╔═══██╗██╔════╝     ██║████╗  ██║██╔════╝██╔═══██╗    ██╔══██╗██║██╔══██╗
██║  ██║██║███████║██║     ██║   ██║██║  ███╗    ██║██╔██╗ ██║█████╗  ██║   ██║    ██║  ██║██║██████╔╝
██║  ██║██║██╔══██║██║     ██║   ██║██║   ██║    ██║██║╚██╗██║██╔══╝  ██║   ██║    ██║  ██║██║██╔══██╗
██████╔╝██║██║  ██║███████╗╚██████╔╝╚██████╔╝    ██║██║ ╚████║██║     ╚██████╔╝    ██████╔╝██║██║  ██║
╚═════╝ ╚═╝╚═╝  ╚═╝╚══════╝ ╚═════╝  ╚═════╝     ╚═╝╚═╝  ╚═══╝╚═╝      ╚═════╝     ╚═════╝ ╚═╝╚═╝  ╚═╝
                                                                                                       */}

            <Dialog open={infoDir!==""? true : false} onClose={() => setInfoDir("")}
                        aria-labelledby="alert-dialog-title4"
                        aria-describedby="alert-dialog-description4"
            >
                <DialogInfoDir folder={infoDir} />

            </Dialog>


 {/* 
 ██████╗ ██████╗ ███╗   ██╗███████╗██╗██████╗ ███╗   ███╗    ██████╗ ███████╗██╗     ███████╗████████╗███████╗    ██╗      ██████╗  ██████╗  ██████╗ ████████╗██╗   ██╗██████╗ ███████╗
██╔════╝██╔═══██╗████╗  ██║██╔════╝██║██╔══██╗████╗ ████║    ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝    ██║     ██╔═══██╗██╔════╝ ██╔═══██╗╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝
██║     ██║   ██║██╔██╗ ██║█████╗  ██║██████╔╝██╔████╔██║    ██║  ██║█████╗  ██║     █████╗     ██║   █████╗      ██║     ██║   ██║██║  ███╗██║   ██║   ██║    ╚████╔╝ ██████╔╝█████╗  
██║     ██║   ██║██║╚██╗██║██╔══╝  ██║██╔══██╗██║╚██╔╝██║    ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝      ██║     ██║   ██║██║   ██║██║   ██║   ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  
╚██████╗╚██████╔╝██║ ╚████║██║     ██║██║  ██║██║ ╚═╝ ██║    ██████╔╝███████╗███████╗███████╗   ██║   ███████╗    ███████╗╚██████╔╝╚██████╔╝╚██████╔╝   ██║      ██║   ██║     ███████╗
 ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝     ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝    ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝    ╚══════╝ ╚═════╝  ╚═════╝  ╚═════╝    ╚═╝      ╚═╝   ╚═╝     ╚══════╝
                                                                                                                                                                                       
                                                                                                                                                                                       
            */}

            <Dialog open={parseInt(confimDeleteLogotype) > 0 ? true : false} onClose={() => setConfimDeleteLogotype(0)}
                        aria-labelledby="alert-dialog-title5"
                        aria-describedby="alert-dialog-description5"
                >
                <DialogTitle id="alert-dialog-title5">{"Confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{marginTop:"20px", textAlign:"center"}} id="alert-dialog-description5">
                        Are you sure delete logotype for this channel?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        sendDataToServer({op: "deleteLogo", chid: confimDeleteLogotype}).then(r => {
                            if (r["status"]==="OK"){
                                window.location.href=""
                            }
                        })
                    }}>Yes</Button>
                    <Button onClick={() => setConfimDeleteLogotype(0)}>No</Button>
                </DialogActions>
            </Dialog>
 

            
            </>
    )
}
