import './App.css';
import * as React from 'react';
import { Box, Container } from '@mui/material';
import MenuTop from './components/MenuTop';
import Cookies from 'js-cookie';
import RoutesUser from './utils/RoutesUser'
import RoutesGuest from './utils/RoutesGuest'
import { Link } from 'react-router-dom';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import { useEffect } from 'react';








function App() {

  const user = Cookies.get('user');
  const currentYear = new Date().getFullYear();

  useEffect(function(){
    document.title = "TVHOST v" + process.env.REACT_APP_VERSION + " Dashboard"
  }, [])

  return (
    <>

    {user ? <MenuTop /> : ""}

    <Container maxWidth="xl">
      <Box sx={{
                bgcolor: 'background.paper',
                boxShadow: 2,
                borderRadius: 2,
                p: 3,
                minWidth: 300,
                mt:3
          }}>
          
            {(user) ? <RoutesUser /> : <RoutesGuest />}
          
        </Box>

        <Box sx={{
              // bgcolor: '#a3aab1',
              boxShadow: 0,
              borderRadius: 2,
              p: 2,
              minWidth: 300,
              mt:2,
              textAlign:'center',
              fontSize:'12px',
              color:'text.secondary'
        }}><div>&copy;2023-{currentYear} Copyright <Link to="https://tvhost.cc" target="_blank">TVHOST.CC</Link> v{process.env.REACT_APP_VERSION}. All rights reserved.</div>
           <div>Created with <FavoriteBorder style={{color: "rgb(255, 56, 103)", fontSize: 13, position:"relative", top:"2px"}}/> for <b>ReactJS</b>. Our group <Link to="https://t.me/tvhost_cc" target="_blank">@tvhost_cc</Link></div>
        </Box>
    </Container>



    </>
  );
}

export default App; 
