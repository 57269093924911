import React, { useEffect, useState } from 'react'
import AssessmentIcon from '@mui/icons-material/Assessment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, TextField, Typography } from '@mui/material';
import { convUnixDate, ins, sendDataToServer } from '../utils/functions';



export default function LogsPage(props){

    const [logs, setLogs] = useState([])
    const [clr, setClr] = useState(false)
    const [update, setUpdate] = useState(true)
    const [search, setSearch] = useState("")



    useEffect(() => {
        var s = ""
        const updateEverySecond = () => {
            if (update || search !== s) {
                sendDataToServer({ 'op': "getLogs", 'search': search })
                .then(r => setLogs(r));
                s = search
            }
        }
        const intervalId = setInterval(updateEverySecond, 500);
        return () => clearInterval(intervalId);
    }, [update, search]);


    function handleClick(){
        setClr(true)
    }



    const handleConfirm = () => {
        sendDataToServer({'op':"clearLogs"})
        setClr(false)
    }


    return(
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2><AssessmentIcon style={{ marginRight: "10px" }} />
                Server logs</h2>
                <span>
                    <FormControlLabel
                        onChange={e => setUpdate(e.target.checked)}
                        style={{marginTop: "25px"}}
                        control={<Checkbox defaultChecked />}
                        label="Autoupdate" />
                </span>
                <span>
                    <TextField
                        style={{marginTop: "15px"}}
                        id="searchReg"
                        label="Search regexp"
                        type="search"
                        variant="standard"
                        //value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </span>
                <span>
                    <Button onClick={handleClick} style={{marginTop: "30px"}}><DeleteForeverIcon /> Clear</Button>
                </span>
            </div>

            <hr />
            <Typography variant="body1" className="logs" sx={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                {
                    logs.length === 0 ? ( <span className="loader-center"><CircularProgress color="inherit" /></span> ) : (
                            logs.map((v, k) => (
                                <li className={v.c_group==="ECM" ? "colorGreen" : (v.c_group==="EMM" ? "colorYellow" : (v.c_group==="ERR" ? "colorRed" : ""))}
                                key={k}>{convUnixDate(v.c_time)} [{v.c_group}] {ins(v.c_msg, search)}</li>
                            ))
                    )
            }  
            </Typography>



            <Dialog open={clr} onClose={setClr}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
                <DialogContent><DialogContentText id="alert-dialog-description">
                    Are you sure clear LOG in database?
                </DialogContentText></DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirm}>Yes</Button>
                    <Button onClick={() => setClr(false)}>No</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

