import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom';
import ChannelsPage from '../pages/ChannelsPage'
import StreamerPage from '../pages/StreamerPage'
import LogsPage from '../pages/LogsPage'
import ConfigPage from '../pages/ConfigPage'
import LogoutPage from '../pages/LogoutPage'
import NotFoundPage from '../pages/NotFoundPage'
import { sendDataToServer } from './functions';



export default function RoutesUser(props){

    const [paths, setPaths] = useState([])

    useEffect(function(){
        sendDataToServer({ 'op': "getPaths" })
            .then(r => {
                setPaths(r["paths"].split(","))
            })
    }, [])

    return(
        <Routes>
            <Route path='/' element={<ChannelsPage />} />
            <Route path='/channels' element={<ChannelsPage />} />
            {
                paths.map(v => {
                    switch (v) {
                        case "streamer":
                            return <Route key={v} path={'/'+v} element={<StreamerPage />} />;
                        case "logs":
                            return <Route key={v} path={'/'+v} element={<LogsPage />} />;
                        default:
                            return null;
                    }
                })
            }
            <Route path='/config' element={<ConfigPage />} />
            <Route path='/logout' element={<LogoutPage />} />
            <Route path='/*' element={<NotFoundPage />} />
        </Routes>
    );
}
